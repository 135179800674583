import {createContext} from "react";

export interface LinkToContextValue {
    apiUrl: string;
}

export const LinkToContext = createContext<LinkToContextValue>(
    // @ts-ignore
    undefined
);
